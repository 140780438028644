@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body{
    font-family: 'Inter', sans-serif;
   /* background-color: #f5f5f5 !important;*/
}
.welcome-modal .modal-body{
    padding: 1.2rem;
}
.welcome-modal .modal-content {
    border-radius: 28px;
}
.welcome-modal .modal-header .close {
    padding: 0.2rem 0.62rem;
    box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 0.357rem;
    background: #fff;
    position: absolute;
    right: 35px;
    top: 35px;
}
.welcome-modal .modal-header {
    background-color: #fff;
    border-radius: 0.42rem;
    padding: 0px !important;
    border-bottom: none;
}
.welcome-modal .modal-header .close { 
    right: 35px;
    padding: 8px 9px;
    background: #6F6B6B;
    color: #fff;
    box-shadow: none;
    border-radius: 50px;
}
.welcome-modal .modal-header .close span {
    color: #fff;
    line-height: 18px;
}
.welcome-modal .modal .modal-header .close:hover, .welcome-modal .modal .modal-header .close:focus, .welcome-modal .modal .modal-header .close:active{
  transform: none !important;
}
.welcome-modal .modal-header  img {
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
}
.fw-bold{
    font-weight: bold;
}
.welcome-modal ol{
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    padding: 0;
    margin-bottom: 4em;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
}

.welcome-modal ol{
    margin: 0 0 0 2em;
}
.number-list ol{
    counter-reset: li;
    list-style: none;
    *list-style: decimal;
    padding: 0;
    margin-bottom: 1em;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
}

  .rounded-list li{
    position: relative;
    display: block;
    padding: .4em .4em .4em 2em;
    *padding: .4em;
    margin: .5em 0;
    color: #444;
    text-decoration: none;
    border-radius: .3em;
    transition: all .3s ease-out;
  }


  .rounded-list a:hover:before{
    transform: rotate(360deg);
  }

  .rounded-list li:before{
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -1.3em;
    top: 43%;
    margin-top: -1.3em;
    background: #C4C4C4;
    height: 40px;
    width: 40px;
    line-height: 2em;
    border: .3em solid #fff;
    text-align: center;
    font-weight: bold;
    border-radius: 2em;
    transition: all .3s ease-out;
  }
.ref-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: #fff;
}
.card-ft{
    background-color: rgb(247, 247, 247);
    border-top: 0px !important;
}
.table > * > * {
    border: 1px solid #dedede;
    padding: 10px 15px;
}
.rounded-10{
    border-radius: 10px;
}
.bottom-rounded-10{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.bg-light-blue{
    background: #C9E5FF;
    color: #0069A4 !important;
}
.horizontal-layout .content .content-wrapper {
    padding-bottom: 100px !important;
}
.refund-content{
    background: #F8F8F8;
    border: 1px dashed #C4C4C4;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 1rem;
}
.refund-modal .modal-content {
    border-radius: 28px;
}
.refund-modal .form-control {
    min-height: 45px;
}
.refund-modal .modal-content{
    padding: 1rem;
    padding-bottom: 1.8rem;
}
.refund-modal .modal-header .close {
  padding: 11px 13px;
  background: #6F6B6B;
  box-shadow: none;
  border-radius: 50px;
}
.refund-modal .modal-header .close span {
  font-weight: 400;
  font-size: 2rem;
  color: #fff;
  line-height: 15px;
}
.refund-wrapper .title{
	color: #fff;
	line-height: 65px;
	text-align: center;
	background: #333;
	font-size: 25px;
	font-weight: 500;
  }
  .refund-wrapper .box{
    min-width: 160px;
    padding: 1rem;
    float: left;
	text-align: center;
  }
 .refund-wrapper .box .img {
    text-align: center;
    background: #4B4B4B;
    padding: 17px 15px;
    fill: #fff;
    border-radius: 50px;
    height: 90px;
    width: 90px;
}
.refund-wrapper .box label {
    border-radius: 10px;
    padding: 0px 25px;
    position: relative;
    cursor: default;
    position: relative;
    padding: 10px 20px;
}
  .refund-wrapper .box label{
	display: table;
	height: 53px;
	width: 100%;
	align-items: center;
	transition: all 0.3s ease;
  }
  #option-1:checked ~ .option-1,
  #option-2:checked ~ .option-2,
  #option-3:checked ~ .option-3,
  #option-4:checked ~ .option-4{
	background: #A5DEFF;
  }
  #option-1 ~ .option-1,
  #option-2 ~ .option-2,
  #option-3 ~ .option-3,
  #option-4 ~ .option-4{
    background: #E7E7E7;
    border-radius: 15px;
  }
  .refund-wrapper .box label .dot{
	height: 20px;
	width: 20px;
	background: #d9d9d9;
	border-radius: 50%;
	transition: all 0.3s ease;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  #option-1:checked ~ .option-1 .dot,
  #option-2:checked ~ .option-2 .dot,
  #option-3:checked ~ .option-3 .dot,
  #option-4:checked ~ .option-4 .dot{
	background: #fff;
  }
  .refund-wrapper .box label .dot::before{
	position: absolute;
	content: "";
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(2);
	width: 9px;
	height: 9px;
	border-radius: 50%;
	transition: all 0.3s ease;
  }
  #option-1:checked ~ .option-1 .dot::before,
  #option-2:checked ~ .option-2 .dot::before,
  #option-3:checked ~ .option-3 .dot::before,
  #option-4:checked ~ .option-4 .dot::before{
	background: #333;
	transform: translate(-50%, -50%) scale(1);
  }
  .refund-wrapper .box label .text{
	color: #4B4B4B;
	font-size: 18px;
	font-weight: 600;
	transition: color 0.3s ease;
  }
  #option-1:checked ~ .option-1 .text,
  #option-2:checked ~ .option-2 .text,
  #option-3:checked ~ .option-3 .text,
  #option-4:checked ~ .option-4 .text{
	color: #00254F;
  }
  .refund-wrapper .box input[type="radio"]{
	display: none;
  }

  #option-1:checked ~ .option-1 .img,
  #option-2:checked ~ .option-2 .img,
  #option-3:checked ~ .option-3 .img,
  #option-4:checked ~ .option-4 .img{
	background: #1676E4;
  }
  .popover {
    z-index: 100;
}
.w550{
  width: 500px;
}
.w550 .ant-drawer-content-wrapper{
  width: 500px !important;
}
.mh300{
  min-height: 300px;
}
.roominfo-popupwrapper .popup {
  min-width: 320px;
  max-width: 650px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .refer-page .d-flex{
    display: block !important;

  }
  .collection-data.d-flex{
    display: block !important;
  }
  .collection-data .d-flex{
    display: block !important;
  }
  .m-flex{
    display: block !important;
  }
  .inner-box {
    padding: 10px;
}
  body .vertical-layout.navbar-sticky .app-content .navbar-container {
     padding-left: 0.2rem !important ;
}
.agent-mobile{
  min-height: 170px;
}
.travelpopup {
  padding: 0px !important;

}
.travel-button {
  position: unset !important;
  padding: 10px;
}
.transport-place-time > span {
  padding-right: 5px;
}
.payment-history-table-new .table-head div {
  width: 150px;
}
.booking-history-table-new .table-head div {
  width: 150px;
}
.mobile-dropdown .dropdown-menu {
  left: auto !important;
  right: 0px !important;
}
.addons-count-selected {
  width: 100% !important;
}
.w550{
  width: 100% !important;
}
.w550 .ant-drawer-content-wrapper{
  width: 100% !important;
}
.mh170{
  min-height: 170px !important;
}
.mp20.dashboard-header .date-header {
  padding: 0 0px;
}
.mform-row{
  flex-wrap: nowrap;
}
}
.gridpadding{
  padding: 16px !important;
}
.wallet-nav .nav.nav-tabs .nav-item .nav-link {
  color: #000;
  font-size: 16px;
  min-width: auto;
  font-weight: 500 !important;
}
.badge-green{
  background-color: #006033;
  color: #fff;
}
.modal-book h1{
  color: #0C1F58;
}
.modal-book p{
  margin-bottom: 8px;
  font-size: 16px;
}
.wtext{
  color: #006033;
  font-weight: 600;
  padding-left: 8px;
  padding-right: 8px;

}
.booking-full-modal .modal-header .close {
  padding: 0.2rem 0.62rem;
  box-shadow: none; 
  background: #6F6B6B;
  right: 25px;
  top: 25px;
  height: 50px;
  width: 50px;
  border-radius: 50px;
}
.booking-full-modal .modal-header .close span {
  font-weight: 400;
  font-size: 2rem;
  color: #fff;
  vertical-align: middle;
}
.p20{
  padding: 20px;
}

.bg-light-grey {
  background-color: #E8E8E8;
}
.p16{
  padding: 16px !important;
}
/* ----------- iPad Pro 12.9" ----------- */

@media only screen and (max-width: 1024px) {
  html body .content .content-wrapper {
    padding-bottom: 100px !important;
  }
}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .room-price-total {
      max-width: 1200px;
      padding-bottom: 300px !important;
    }
}
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .room-price-total {
      max-width: 1200px;
      padding-bottom: 300px !important;
    }
}
.z-index-btn {
  z-index: 10000000000;
  position: relative;
}

.bk-head ul{
  list-style-type: circle;
}

.trem-txt{
  color : #b16219;
  font-weight: 600;
}

.font-bold {
  font-weight: bold;
}


.new-look .form-floating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.5rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  font-size: 11px;
  line-height: 11px;
  color: #747474 !important;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}
/* .new-look .form-check-input{
  border-radius: 50px !important;
  width: 2em;
  height: 2em;
} */
.new-look .form-floating .form-control {
  padding-right: 40px !important;
  padding-left: 10px !important;
}

.new-look .iput-icon {
position: absolute;
padding: 12px 10px;
z-index: 1;
right: 14px;
}
.new-look .form-floating label {
padding-left: 23px;
width: 100%;
}
.inner-bx{
background-color: #f6f6f6;
border: 1px solid #efefef;
}
.new-look .room-floating .select__control, .new-look .room-floating .select__control, .new-look .room-floating .promoselect__control {
padding-top: 0.5rem;
padding-bottom: .5rem;
border-color: hsl(0,0%,80%);
}
.new-look .plabel{
position: absolute;
top: 4px;
padding-left: 11px;
font-size: 11px;
}
.new-look .form-floating .form-control{
min-height: 50px;
}
.price-close-btn {
  text-align: center;
  margin-right: 0px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  line-height: 36px;
  background: #6a6a6a;
  border-radius: 520px;
  fill: #fff;
}
.paxDetails .modal-body {
  margin: 0px 20px;
  padding: 0rem;
}
.z-index-99{  
    z-index: 99 !important;
}